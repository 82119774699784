import styled from "styled-components";
import {SM_MIN_SIZE} from "../../util/ViewSizeUtils";

export const StyledModalGallery = styled.div`
  width: 100vw;
  max-width: 1440px;

  @media only screen and (min-width: ${SM_MIN_SIZE}px) {
    width: 100vw;
    max-width: 100vh;
  }

  max-height: 80vh;

  .rec-carousel-item {
    margin-top: auto;
    margin-bottom: auto;
  }

  img {
    max-width: 100%;
    max-height: 80vh;
    width: auto;
    height: auto;
  }
`;
