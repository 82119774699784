import styled from "styled-components";
import {Colors} from "../../../style/ColorUtils";

export const StyledLogo = styled.div`{
  width: 382px;
  margin-top: 22px;
  margin-left: 5px;
  color: ${Colors.WHITE};
  text-align: center;
  font-size: 18px;
  font-family: bely-bold;
  @media only screen and (max-width: ${487}px) {
    width: 250px;
    margin-top: 5px;
    margin-left: 0;
  }
}`
