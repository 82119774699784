import * as React from "react";
import {FC} from "react";
import {translate} from "./TranslationUtils";

interface TranslateProps {
    translationKey: string;
}

export const Translate: FC<TranslateProps> = (props: TranslateProps) => {
    return <>{translate(props.translationKey)}</>;
}
