import React, {FC} from "react";
import {StyledFooter} from "./Footer.style";
import {Spacer} from "../spacer/Spacer.style";
import {FooterLogo} from "./footerLogo/FooterLogo.component";
import {FooterAddress} from "./footerAdress/FooterAddress.component";
import {FooterMenu} from "./footerMenu/FooterMenu.component";

interface FooterProps {
}

export const Footer: FC<FooterProps> = (props: FooterProps) => {
    return <StyledFooter>
        <Spacer height={"17px"}/>
        <div className={"row footer"}>
            <FooterMenu className="col-xs-12 col-sm-4 col-md-4 col-lg-4"/>
            <FooterLogo className="col-xs-12 col-sm-4 col-md-4 col-lg-4"/>
            <FooterAddress className="col-xs-12 col-sm-4 col-md-4 col-lg-4"/>
        </div>
    </StyledFooter>;
};
