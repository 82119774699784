import React, {FC} from "react";
import {StyledModalGallery} from "./ModalGallery.style";
import {Modal} from "../modal/Modal.component";
import {ImageCustom} from "../image/ImageCustom.component";
import {ImageProps} from "../carousel/service/Carousel.service";
import {GalleryCarousel} from "./GalleryCarousel.component";
import {isNotDefined} from "../../util/ObjectUtils";

interface ModalGalleryProps {
    isOpen: boolean;
    closeModal: () => void;
    images: ImageProps[];
    currentImageKey?: number;
}

export const ModalGallery: FC<ModalGalleryProps> = (props: ModalGalleryProps) => {
    const getActiveIndex = (): number | undefined => {
        if (isNotDefined(props.currentImageKey)) {
            return undefined;
        }

        return props.images.findIndex(value => value.key === props.currentImageKey);
    }

    return <Modal isOpen={props.isOpen} closeModal={props.closeModal}>
        <StyledModalGallery>
            <GalleryCarousel activeIndex={getActiveIndex()}>
                {props.images.map(image => <ImageCustom key={image.key} image={image}/>)}
            </GalleryCarousel>
        </StyledModalGallery>;
    </Modal>
};
