import styled from "styled-components";
import {XS_MAX_SIZE} from "../../util/ViewSizeUtils";
import {Colors} from "../../style/ColorUtils";

export const CloseButton = styled.div`
  position: absolute;
  top: -30px;
  right: -30px;

  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    right: 7px;
  }

  font-size: 30px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  color: ${Colors.WHITE};
  text-align: center;
  cursor: pointer;
`;
