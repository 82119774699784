import React, {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RenderArrowProps} from "react-elastic-carousel";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {StyledArrow} from "./Arrow.style";

interface LeftArrowProps extends RenderArrowProps {
}

export const Arrow: FC<LeftArrowProps> = (props: LeftArrowProps) => {
    const isLeft = (): boolean => props.type === "PREV"

    return <StyledArrow isLeft={isLeft()}>
        <FontAwesomeIcon
            onClick={props.onClick}
            style={{visibility: props.isEdge ? "hidden" : "visible"}}
            icon={isLeft() ? faAngleLeft : faAngleRight}/>
    </StyledArrow>;
};
