// Only one item MUST have the "default: true" key

import {PL_MESSAGES} from "./translations/pl";
import {EN_MESSAGES} from "./translations/en";

export const translationConfig: { [key: string]: any } = {
    pl: {
        default: true,
        path: `pl`,
        locale: `pl-PL`,
        dateFormat: `DD/MM/YYYY`,
        siteLanguage: `pl`,
        ogLanguage: `pl_PL`,
        defaultTitle: `Using i18n with Gatsby`,
        defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
        messages: PL_MESSAGES
    },
    en: {
        path: `en`,
        locale: `en-US`,
        dateFormat: `DD.MM.YYYY`,
        siteLanguage: `en`,
        ogLanguage: `en_US`,
        defaultTitle: `i18n mit Gatsby nutzen`,
        defaultDescription: `Gatsby Beispielseite, die MDX und i18n (frei von dependencies) nutzt`,
        messages: EN_MESSAGES
    }
}
