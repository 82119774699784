import React, {FC} from "react";
import {StyledFooterMenu} from "./FooterMenu.style";
import {menuRoutes} from "../../menu/service/Menu.service";
import {LocalizedLink} from "../../localizedLink/LocalizedLink.component";

interface FooterMenuProps {
    className: string;
}

export const FooterMenu: FC<FooterMenuProps> = (props: FooterMenuProps) => {
    return <StyledFooterMenu className={props.className}>
        <ul>
            {menuRoutes.map(route =>
                <li key={route.pathName}>
                    <LocalizedLink to={route.link} label={route.label}/>
                </li>)}
        </ul>
    </StyledFooterMenu>;
};
