import React, {FC} from "react";
import {StyledSocialMedia} from "./SocialMedia.style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareFacebook, faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import {getFacebookLink, getInstagramLink} from "./service/SocialMedia.service";
import {ExternalLink} from "../externalLink/ExternalLink.component";

interface SocialMediaProps {
}

export const SocialMedia: FC<SocialMediaProps> = (props: SocialMediaProps) => {
    return <StyledSocialMedia>
        <div>
            <ExternalLink to={getFacebookLink()} ariaLabel={"FACEBOOK_LINK_ARIA_LABEL"}>
                <FontAwesomeIcon className={"f-social-media-icon"} icon={faSquareFacebook}/>
            </ExternalLink>
        </div>
        <div>
            <ExternalLink to={getInstagramLink()} ariaLabel={"INSTAGRAM_LINK_ARIA_LABEL"}>
                <FontAwesomeIcon className={"i-social-media-icon"} icon={faSquareInstagram}/>
            </ExternalLink>
        </div>
    </StyledSocialMedia>;
};
