import React, {FC} from "react";
import {StyledMenuButton} from "./MenuButton.style";
import {LocalizedLink} from "../../localizedLink/LocalizedLink.component";

interface MenuButtonProps {
    link: string;
    label: string
}

export const MenuButton: FC<MenuButtonProps> = (props: MenuButtonProps) => {
    return <StyledMenuButton>
        <LocalizedLink to={props.link} label={props.label}/>
    </StyledMenuButton>;
};
