import React, {FC} from "react";
import {StyledFooterLogo} from "./FooterLogo.style";
import {DesignedByWebrance} from "../../designedByWebrance/DesignedByWebrance.component";
import {Images} from "../../../../images/Images";
import {ImageCustom} from "../../image/ImageCustom.component";

interface FooterLogoProps {
    className: string;
}

export const FooterLogo: FC<FooterLogoProps> = (props: FooterLogoProps) => {
    return <StyledFooterLogo className={props.className}>
        <div className={"logo-square"}>
            <ImageCustom image={Images.LOGO_SQUARE}/>
        </div>
        <DesignedByWebrance/>
    </StyledFooterLogo>;
};
