// @ts-nocheck
import {ImageProps} from "../common/components/carousel/service/Carousel.service";

import GALLERY_PICTURE_1 from "./gallery/tatuaz smoka.webp";
import GALLERY_PICTURE_2 from "./gallery/tatuaz Itachi z Naruto.webp";
import GALLERY_PICTURE_3 from "./gallery/tatuaz oczy ze skrzydlami.webp";
import GALLERY_PICTURE_4 from "./gallery/tatuaz nozyczki z motylem.webp";
import GALLERY_PICTURE_5 from "./gallery/tatuaz ptaka z dwoma glowami.webp";
import GALLERY_PICTURE_6 from "./gallery/voucher lesny szept.webp";
import GALLERY_PICTURE_7 from "./gallery/kolczyk bridge i septum.webp";

import WEBRANCE_LOGO from "./common/webrance-logo.svg";
import PL_FLAG from "./common/pl.svg";
import EN_FLAG from "./common/gb.svg";
import LOGO_SQUARE from "./common/lesny-szept-logo-zlote.svg";
import LOGO_RECT from "./common/lesny-szept-tatto-and-piercing.svg";
import MASK from "./common/maseczka.svg";
import TATTOO_MACHINE from "./common/maszynka-do-tatuazu.svg";
import PALETTE from "./common/paletka.svg";

import WALK_IN from "./common/walkin.jpg";
import {getKey} from "./ImageKeyGenerator.service";

export class Images {
    static WALK_IN: ImageProps = {key: getKey(), image: WALK_IN, alt: "WALK_IN_ALT"};

    static WEBRANCE_LOGO: ImageProps = {key: getKey(), image: WEBRANCE_LOGO, alt: "WEBRANCE_LOGO_ALT"};

    static LOGO_SQUARE: ImageProps = {key: getKey(), image: LOGO_SQUARE, alt: "LOGO_SQUARE_ALT"};
    static LOGO_RECTANGLE: ImageProps = {key: getKey(), image: LOGO_RECT, alt: "LOGO_RECTANGLE_ALT"};

    static PL_FLAG: ImageProps = {key: getKey(), image: PL_FLAG, alt: "PL_ALT"};
    static EN_FLAG: ImageProps = {key: getKey(), image: EN_FLAG, alt: "EN_ALT"};

    static GALLERY_PICTURE_1: ImageProps = {key: getKey(), image: GALLERY_PICTURE_1, alt: "GALLERY_PICTURE_1_ALT"};
    static GALLERY_PICTURE_2: ImageProps = {key: getKey(), image: GALLERY_PICTURE_2, alt: "GALLERY_PICTURE_2_ALT"};
    static GALLERY_PICTURE_3: ImageProps = {key: getKey(), image: GALLERY_PICTURE_3, alt: "GALLERY_PICTURE_3_ALT"};
    static GALLERY_PICTURE_4: ImageProps = {key: getKey(), image: GALLERY_PICTURE_4, alt: "GALLERY_PICTURE_4_ALT"};
    static GALLERY_PICTURE_5: ImageProps = {key: getKey(), image: GALLERY_PICTURE_5, alt: "GALLERY_PICTURE_5_ALT"};
    static GALLERY_PICTURE_6: ImageProps = {key: getKey(), image: GALLERY_PICTURE_6, alt: "GALLERY_PICTURE_6_ALT"};
    static GALLERY_PICTURE_7: ImageProps = {key: getKey(), image: GALLERY_PICTURE_7, alt: "GALLERY_PICTURE_7_ALT"};

    static PALETTE: ImageProps = {key: getKey(), image: PALETTE, alt: "PALETTE_ALT"};
    static TATTOO_MACHINE: ImageProps = {key: getKey(), image: TATTOO_MACHINE, alt: "TATTOO_MACHINE_ALT"};
    static MASK: ImageProps = {key: getKey(), image: MASK, alt: "MASK_ALT"};

    static getFlag = (countryName: string): ImageProps => {
        switch (countryName.toUpperCase()) {
            case "PL":
                return Images.PL_FLAG;
            case "EN":
                return Images.EN_FLAG;
        }
        throw new Error("Flag for: " + countryName + " does not exist");
    }

    static FULL_GALLERY: ImageProps[] = [
    ];

    private constructor() {
    }
}
