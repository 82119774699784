import React, {FC} from "react";
import {StyledFooterAddress} from "./FooterAddress.style";
import {Translate} from "../../../../lang/Translate.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {getGoogleMapsLink} from "./service/FooterAdress.service";
import {ExternalLink} from "../../externalLink/ExternalLink.component";

interface FooterAddressProps {
    className: string
}

export const FooterAddress: FC<FooterAddressProps> = (props: FooterAddressProps) => {
    return <StyledFooterAddress className={props.className}>
        <ExternalLink className={"footer-address"} to={getGoogleMapsLink()}>
            <div className={"footer-address-icon"}>
                <FontAwesomeIcon icon={faLocationDot}/>
            </div>
            <div>
                <div className={"footer-address-name"}>
                    <Translate translationKey={"LESNY_SZEPT"}/>
                </div>
                <div className={"footer-address-address"}>
                    <Translate translationKey={"ADDRESS_STREET"}/>
                </div>
                <div className={"footer-address-address"}>
                    <Translate translationKey={"ADDRESS_CITY"}/>
                </div>
            </div>
        </ExternalLink>
    </StyledFooterAddress>;
};
