import styled from "styled-components";
import {Colors} from "../../../style/ColorUtils";

export const StyledMenuItem = styled.li`
    float: left;
    margin-top: 24px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 24px;
  
  a {
    text-decoration: none;
    color: ${Colors.WHITE};
    padding: 7px 16px;
    --border-color: ${Colors.YELLOW};
    --border-width: 4px;
    --bottom-distance: 0px;

    display: inline-block;
    background-image: linear-gradient(var(--border-color), var(--border-color));
    background-size: 0 var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.2s;
    background-position: 50% calc(100% - var(--bottom-distance));
  }

  a:hover {
    background-size: 100% var(--border-width);
  }

  .selected {
    background-size: 100% var(--border-width);
  }

`;
