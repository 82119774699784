import styled from "styled-components";
import {Colors} from "../../../style/ColorUtils";

export const StyledMenuButton = styled.li`
  float: left;
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 24px;

  a {
    display: inline-block;
    text-decoration: none;
    padding-top: 7px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 7px;
    background-color: ${Colors.WHITE};
    font-weight: bold;
    border-radius: 7px;
    width: 111px;
    line-height: 37px;
    text-align: center;
    color: black;
    mix-blend-mode: lighten;
  }
`;
