import React, {FC} from "react";
import {StyledDesignedByWebrance} from "./DesignedByWebrance.style";
import {Images} from "../../../images/Images";
import {Translate} from "../../../lang/Translate.component";
import {getWebranceLink} from "./service/DesignedByWebrance.service";
import {ExternalLink} from "../externalLink/ExternalLink.component";
import {ImageCustom} from "../image/ImageCustom.component";

interface DesignedByWebranceProps {
}

export const DesignedByWebrance: FC<DesignedByWebranceProps> = (props: DesignedByWebranceProps) => {
    return <StyledDesignedByWebrance>
        <ExternalLink to={getWebranceLink()}>
            <div className={"by-webrance-label"}>
                <Translate translationKey={"BY_WEBRANCE"}/>
            </div>
            <div className={"webrance-logo"}>
                <ImageCustom image={Images.WEBRANCE_LOGO}/>
            </div>
        </ExternalLink>
    </StyledDesignedByWebrance>;
};
