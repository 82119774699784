import {translationConfig} from "./i18n";
import {isDefined, isNotDefined} from "../common/util/ObjectUtils";
import {useContext} from "react";
import {LocaleContext} from "./context/LocaleContext";
import {navigate} from "gatsby";

export const translate = (key: string | undefined): string => {
    if (isNotDefined(key)) {
        return "";
    }

    const translation = translationConfig[getLanguage()].messages[key as string];
    return isDefined(translation) ? translation : key;
}

export const isLanguageDefault = (lang: string): boolean => {
    let isLanguageDefault = translationConfig[lang].default;
    return isDefined(isLanguageDefault) ? isLanguageDefault : false;
}

export const getDefaultLanguage = (): string => {
    return getAvailableLanguages().find(value => isLanguageDefault(value)) as string;
}

export const getAvailableLanguages = (): string[] => {
    return Object.keys(translationConfig);
}

export const changeLanguage = (lang: string) => {
    if (!getAvailableLanguages().includes(lang)) {
        throw new Error('Language is not available: ' + lang);
    }

    if (isNotDefined(window)) {
        throw new Error('Window cannot be used during SSR');
    }

    navigate(getNewPathName(lang));
    document.documentElement.lang=lang;
}

const getNewPathName = (lang: string): string => {
    let splittedPath: string[] = window.location.pathname.split("/").filter(value => value !== '');
    const currentLanguage = getAvailableLanguages().includes(splittedPath[0]) ? splittedPath[0] : getDefaultLanguage();

    if (currentLanguage === lang) {
        return window.location.pathname;
    }

    if (isLanguageDefault(currentLanguage)) {
        return "/" + lang + "/" + splittedPath.join('/');
    }

    if (isLanguageDefault(lang)) {
        return "/" + splittedPath.slice(1, splittedPath.length).join('/');
    }

    splittedPath[0] = lang;
    return "/" + splittedPath.join('/');
}

export const getLocalePath = (lang: string): boolean => {
    return translationConfig[lang].path;
}

export const getLanguage = (): string => {
    return useContext(LocaleContext).locale as string;
}
