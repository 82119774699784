import React, {FC, useState} from "react";
import {StyledImageCustom} from "./ImageCustom.style";
import {ImageProps} from "../carousel/service/Carousel.service";
import {translate} from "../../../lang/TranslationUtils";
import {ModalGallery} from "../modalGallery/ModalGallery.component";
import {isDefined} from "../../util/ObjectUtils";

export interface ImageCustomProps {
    image: ImageProps;
    className?: string;
    gallery?: ImageProps[];
    withPulse?: boolean;
    width?: string;
    height?: string;
}

export const ImageCustom: FC<ImageCustomProps> = (props: ImageCustomProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return <>
        <StyledImageCustom
            onClick={() => setIsOpen(true)}
            src={props.image.image}
            className={props.className}
            alt={translate(props.image.alt)}
            isClickable={isDefined(props.gallery)}
            withPulse={props.withPulse}
            width={props.width}
            height={props.height}
        />
        {isDefined(props.gallery) &&
            <ModalGallery
                closeModal={() => setIsOpen(false)}
                isOpen={isOpen}
                images={props.gallery as ImageProps[]}
                currentImageKey={props.image.key}
            />}
    </>;
};
