import * as React from "react";
import {FC, PropsWithChildren} from "react";
import {LocaleContextProvider} from "../context/LocaleContext";

interface LanguageProviderProps {
    lang: string;
}

export const LanguageProvider: FC<PropsWithChildren<LanguageProviderProps>> = (props: PropsWithChildren<LanguageProviderProps>) => {
    return (
        <LocaleContextProvider value={{locale: props.lang}}>
            {props.children}
        </LocaleContextProvider>
    )
}
