import * as React from 'react'
import {FC} from 'react'
import {DesktopMenu} from "./desktopMenu/DesktopMenu.component";
import {MobileMenu} from "./mobileMenu/MobileMenu.component";

interface Menu {
    selectedPageName?: string;
}

export const Menu: FC<Menu> = (props: Menu) => {
    return <>
        <DesktopMenu selectedPageName={props.selectedPageName}/>
        <MobileMenu selectedPageName={props.selectedPageName}/>
    </>
}
