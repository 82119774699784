import styled from "styled-components";
import {Colors} from "../../../style/ColorUtils";
import {XS_MAX_SIZE} from "../../../util/ViewSizeUtils";

export const StyledFooterMenu = styled.div`
  font-size: 28.65px;
  padding-left: 23px;
  padding-top: 27px;
  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    display: none;
  }

  ul {
    margin: 0;
    list-style-type: none;
    overflow: hidden;
  }
  
  li {
    height: 46px;
  }

  a {
    text-decoration: none;
    color: ${Colors.WHITE};
    font-family: bely;
    font-size: 22px;
    transition: all 0.3s ease-in-out;
  }

  a:hover {
    font-size: 23px;
    letter-spacing: 1px;
  }
`;
