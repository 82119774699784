import * as React from 'react'
import {FC} from 'react'
import {LocalizedLink} from "../../localizedLink/LocalizedLink.component";
import {StyledMenuItem} from "./MenuItem.style";

interface MenuItemProps {
    isSelected: boolean;
    link: string;
    label: string;
    onClick?: () => void;
}

export const MenuItem: FC<MenuItemProps> = (props: MenuItemProps) => {
    return (
        <StyledMenuItem onClick={props.onClick ?? props.onClick}>
            <LocalizedLink className={props.isSelected ? "selected" : undefined} to={props.link} label={props.label}/>
        </StyledMenuItem>
    )
}
