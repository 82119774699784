import styled from "styled-components";
import {Colors} from "../../../style/ColorUtils";
import {XS_MAX_SIZE} from "../../../util/ViewSizeUtils";

export const StyledFooterAddress = styled.div`
  padding-top: 29px;
  display: flex;
  
  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    text-align: center;
    order: -1;
  }

  .footer-address {
    color: ${Colors.WHITE};
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
  }
  
  .footer-address:hover {
    .footer-address-address {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
    }
  }
  
  .footer-address-icon {
    padding-top: 8px;
    font-size: 20px;
    padding-right: 10px;
    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      display: none;
    }
  }
  
  .footer-address-address {
    font-family: bely-italic;
    letter-spacing: 1px;
    line-height: 20px;
    font-size: 17px;
  }
  
  .footer-address-name {
    font-family: bely-bold;
    letter-spacing: 2px;
    font-size: 28px;
    padding-bottom: 5px;
  }

  .footer-address-name:hover {
    text-decoration: none;
  }
`;
