import React, {FC} from "react";
import {StyledDesktopMenu} from "./DesktopMenu.style";
import {Logo} from "../logo/Logo.component";
import {menuRoutes} from "../service/Menu.service";
import {MenuItem} from "../menuItem/MenuItem.component";
import {MenuButton} from "../menuButton/MenuButton.component";
import {getBookingsLink} from "../service/Links.service";

interface DesktopMenuProps {
    selectedPageName?   : string;
}

export const DesktopMenu: FC<DesktopMenuProps> = (props: DesktopMenuProps) => {
    const isSelected = (pageName: string): boolean => {
        return props.selectedPageName === pageName;
    }

    return <StyledDesktopMenu className={"row between-xs"}>
        <div className={"col-xs-5 col-sm-5 col-md-5 col-lg-5"}>
            <Logo/>
        </div>
        <nav className={"col-xs-7 col-sm-7 col-md-7 col-lg-7"}>
            <ul className={"row end-xs"}>
                {menuRoutes.slice(0, 3).map(value =>
                    <MenuItem
                        key={value.pathName}
                        label={value.label}
                        isSelected={isSelected(value.pathName)}
                        link={value.link}
                    />)}
                <MenuButton label={"BOOKINGS"} link={getBookingsLink()}/>
            </ul>
        </nav>
    </StyledDesktopMenu>;
};
