import React, {FC, PropsWithChildren} from "react"
import {Link} from "gatsby"
import {getLanguage, getLocalePath, isLanguageDefault, translate} from "../../../lang/TranslationUtils";
import {isDefined} from "../../util/ObjectUtils";

interface LocalizedLinkProps {
    to: string;
    className?: string;
    label?: string;
    openInNewCard?: boolean;
}

export const LocalizedLink: FC<PropsWithChildren<LocalizedLinkProps>> = (props: PropsWithChildren<LocalizedLinkProps>) => {
    const lang: string = getLanguage();
    const isIndex = props.to === `/`;
    const path = isLanguageDefault(lang)
        ? props.to
        : `/${getLocalePath(lang)}${isIndex ? `` : `${props.to}`}`

    return <Link className={props.className} to={path} target={props.openInNewCard ? "_blank" : undefined}>
        {isDefined(props.label) ? translate(props.label) : ""}
        {isDefined(props.children) && props.children}
    </Link>
}
