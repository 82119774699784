import * as React from 'react'
import {FC, PropsWithChildren} from 'react'
import {StyledMainLayout} from "./MainLayout.style";
import {Menu} from "../../common/components/menu/Menu.component";
import {LanguageProvider} from "../../lang/languageProvider/LanguageProvider.component";
import {GlobalStyle} from "../../common/style/GlobalStyle";
import {PageContext} from "../../lang/context/LocaleContext";
import {getDefaultLanguage} from "../../lang/TranslationUtils";
import {Footer} from "../../common/components/footer/Footer.component";
import {SocialMedia} from "../../common/components/socialMediaComponent/SocialMedia.component";

interface MainLayoutProps {
    pageContext: PageContext;
}

export const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = (props: PropsWithChildren<MainLayoutProps>) => {
    let isIndex = props.pageContext.pageName === "index";
    return (
        <LanguageProvider lang={props.pageContext.locale ?? getDefaultLanguage()}>
            <GlobalStyle withBackground={!isIndex}/>
            <StyledMainLayout>
                {!isIndex &&
                    <Menu selectedPageName={props.pageContext.pageName}/>
                }
                <main>{props.children}</main>
                <SocialMedia/>
                <Footer/>
            </StyledMainLayout>
        </LanguageProvider>
    )
}
