export const PL_MESSAGES: any = {
    "HOME_PAGE_TITLE": "Tattoo & Piercing",
    "HOME_PAGE_DESCRIPTION": "Miejsce w którym niepowtarzalny klimat i piękno lasu, spotyka się z nowoczesnością i funkcjonalnością centrum.",
    "PAGE_NOT_FOUND": "Strona nieznaleziona",
    "VIEW_ARTISTS": "Zobacz artystów",
    "VIEW_MORE": "Zobacz wszystko",
    "MAIN_PAGE": "Strona główna",
    "ARTISTS": "Artyści",
    "TATTOOS": "Tatuaże",
    "PIERCING": "Piercing",
    "BOOKINGS": "Zapisy",
    "PRICING": "Cennik",
    "ABOUT_US": "O nas",
    "ABOUT_US_FIRST_BLOCK": "Cechuje nas indywidualne podejście do klienta, precyzja i kreatywność. Tatuaże wychodzące spod naszej igły są niepowtarzalne i idealnie dopasowane do Waszej anatomii, a projekty wykonywane w 100% przez naszych artystów, biorąc pod uwagę wymagania klienta.",
    "ABOUT_US_SECOND_BLOCK": "Nasze studio tworzą młodzi, ambitni, kreatywni i skrupulatnie podchodzący do pracy artyści, których prócz pasji, łączy tez wysokie poczucie estetyki i świeże spojrzenie na sztukę.",
    "ABOUT_US_THIRD_BLOCK": "Sterylność zabiegów, opieka nad klientem i poprawnie wykonany tatuaż to klucz do sukcesu! Higiena w studiu utrzymana jest na najwyższym poziomie. Na miejscu przekażemy Ci również dokładną instrukcje pielęgnacji. Nasza praca nie kończy się w studiu, cały czas jesteśmy do waszej dyspozycji. W razie jakichkolwiek pytań - rozwiejemy Twoje wątpliwości",
    "GALLERY": "Galeria",
    "BY_WEBRANCE": "Designed and developed by",
    "LESNY_SZEPT": "Leśny Szept",
    "ADDRESS_STREET": "ul. Bracka 23/46,",
    "ADDRESS_CITY": "00-020 Warszawa",

    //PRICING
    "MINIMAL_PRICE": "Cena minimalna studia",
    "SMALL_TATTOO": "Mały tatuaż",
    "WHOLE_DAY_SESSION": "Sesja całodzienna",

    "MINIMAL_PRICE_PRICE": "300zł",
    "SMALL_TATTOO_PRICE": "450-800zł",
    "WHOLE_DAY_SESSION_PRICE": "1500zł",
    "CHOKING_PRICE": "od 50zł",

    "LOBE": "Lobe",
    "2_LOBE": "2x Lobe",
    "HELIX": "Helix",
    "FORWARD_HELIX": "Forward helix",
    "VERTICAL_HELIX": "Vertical helix",
    "FLAT": "Flat",
    "CONCH": "Conch",
    "DAITH": "Daith",
    "ROOK": "Rook",
    "SNUG": "Snug",
    "TRAGUS": "Tragus",
    "ANTI_TRAGUS": "Anti tragus",
    "INDUSTRIAL": "Industrial",
    "ORBITAL": "Orbital",
    "RAGNAR": "Ragnar",
    "LEFT_NIPPLE": "Lewy sutek",
    "RIGHT_NIPPLE": "Prawy sutek",
    "BOTH_NIPPLES": "Oba sutki",
    "NOSTRIL": "Nostril",
    "SEPTUM": "Septum",
    "BRIDGE": "Bridge",
    "MANTIS": "Mantis",
    "LABRET": "Labret",
    "MONROE": "Monroe",
    "MADONNA": "Madonna",
    "SMILEY": "Smiley",
    "MEDUSA": "Medusa",
    "JESTRUM": "Jestrum",
    "BITES": "Bites",
    "ASHLEY": "Ashley",
    "STANDARD_TONGUE": "Standard tongue",
    "VENOM": "Venom",
    "NAVEL": "Navel",
    "REVERSE_NAVEL": "Reverse navel",
    "BREW_PIERCING": "Kolczyk w brwi",
    "OUR_CONSULTATION": "Konsultacja usługi wykonanej w Leśny Szept",
    "BAD_CLIENT_CONSULTATION": "Konsultacja po niestosowaniu się do zaleceń",
    "OTHER_PIERCER_CONSULTATION": "Konsultacja usługi wykonanej w innym studio",
    "CHANGE_PIERCING": "Wymiana kolczyka",
    "OTHER_PIERCER_CHANGE_PIERCING": "Wymiana kolczyka wykonanego w innym studio",

    "LOBE_PRICE": "120zł",
    "2_LOBE_PRICE": "180zł",
    "HELIX_PRICE": "130zł",
    "FORWARD_HELIX_PRICE": "130zł",
    "VERTICAL_HELIX_PRICE": "130zł",
    "FLAT_PRICE": "130zł",
    "CONCH_PRICE": "130zł",
    "DAITH_PRICE": "150zł",
    "ROOK_PRICE": "140zł",
    "SNUG_PRICE": "150zł",
    "TRAGUS_PRICE": "130zł",
    "ANTI_TRAGUS_PRICE": "140zł",
    "INDUSTRIAL_PRICE": "150zł",
    "ORBITAL_PRICE": "170zł",
    "RAGNAR_PRICE": "150zł",

    "LEFT_NIPPLE_PRICE": "150zł",
    "RIGHT_NIPPLE_PRICE": "150zł",
    "BOTH_NIPPLES_PRICE": "280zł",
    "NOSTRIL_PRICE": "130zł",
    "SEPTUM_PRICE": "120zł",
    "BRIDGE_PRICE": "130zł",
    "MANTIS_PRICE": "220zł",

    "LABRET_PRICE": "120zł",
    "MONROE_PRICE": "120zł",
    "MADONNA_PRICE": "120zł",
    "MEDUSA_PRICE": "120zł",
    "JESTRUM_PRICE": "120zł",
    "SMILEY_PRICE": "120zł",
    "BITES_PRICE": "240zł",
    "ASHLEY_PRICE": "120zł",
    "STANDARD_TONGUE_PRICE": "150zł",
    "VENOM_PRICE": "250zł",

    "NAVEL_PRICE": "140zł",
    "REVERSE_NAVEL_PRICE": "140zł",

    "BREW_PIERCING_PRICE": "130zł",

    "OUR_CONSULTATION_PRICE": "Za darmo",
    "OTHER_PIERCER_CHANGE_PIERCING_PRICE": "50zł",
    "BAD_CLIENT_CONSULTATION_PRICE": "80zł",
    "OTHER_PIERCER_CONSULTATION_PRICE": "50zł",
    "CHANGE_PIERCING_PRICE": "Cena kolczyka",

    "TATTOS": "Tatuaże",
    "EAR": "Ucho",
    "NIPPLES": "Sutki",
    "NOSE": "Nos",
    "LIPS": "Usta",
    "TONGUE": "Język",
    "BELLY_BUTTON": "Pępek",
    "BREW": "Brew",
    "OTHER": "Inne",

    "BORDER_COMPOSITION": "Obwódka",
    "BUTTERFLY_COMPOSITION": "Motylek",
    "FLOWER_COMPOSITION": "Kwiatek",
    "CROSS_COMPOSITION": "Krzyż",
    "PAW_COMPOSITION": "Łapka",
    "DISCO_COMPOSITION": "Disco Ball",

    "BORDER_COMPOSITION_PRICE": "280zł",
    "BUTTERFLY_COMPOSITION_PRICE": "180zł",
    "FLOWER_COMPOSITION_PRICE": "250zł",
    "CROSS_COMPOSITION_PRICE": "250zł",
    "PAW_COMPOSITION_PRICE": "290zł",
    "DISCO_COMPOSITION_PRICE": "350zł",

    "TOOTH_GEMS": "Tooth gems",
    "TOOTH_GEMS_CUSTOM": "Tooth gems",
    "REMOVING_TOOTH_GEMS": "Zdejmowanie",

    "ONE_GEM": "1 kryształek",
    "TWO_GEMS": "2 kryształki",
    "THREE_GEMS": "3 kryształki",
    "FOUR_GEMS": "4 kryształki",
    "FIVE_SIX_GEMS": "5-6 kryształków",
    "SEVEN_ELEVEN_GEMS": "7-11 kryształków",
    "ELEVEN_FIFTEEN_GEMS": "12-15 kryształków",
    "ANOHTER_GEM": "każdy kolejny",

    "ONE_GEM_PRICE": "70zł",
    "TWO_GEMS_PRICE": "120zł",
    "THREE_GEMS_PRICE": "170zł",
    "FOUR_GEMS_PRICE": "210zł",
    "FIVE_SIX_GEMS_PRICE": "250zł",
    "SEVEN_ELEVEN_GEMS_PRICE": "280zł",
    "ELEVEN_FIFTEEN_GEMS_PRICE": "300zł",
    "ANOHTER_GEM_PRICE": "10zł",

    "OUR_ONE_SIX_GEMS_REMOVING": "1-6 kryształków zrobionych w Leśny Szept",
    "OUR_SEVEN_PLUS_GEMS_REMOVING": "7+ kryształków zrobionych w Leśny Szept",
    "SOMEONE_ONE_SIX_GEMS_REMOVING": "1-6 kryształków zrobionych w innym studio",
    "SOMEONE_ELSE_SEVEN_PLUS_GEMS_REMOVING": "7+ kryształków zrobionych w innym studio",

    "OUR_ONE_SIX_GEMS_REMOVING_PRICE": "60zł",
    "OUR_SEVEN_PLUS_GEMS_REMOVING_PRICE": "100zł",
    "SOMEONE_ONE_SIX_GEMS_REMOVING_PRICE": "80zł",
    "SOMEONE_ELSE_SEVEN_PLUS_GEMS_REMOVING_PRICE": "120zł",

    //PRICING END

    "ARE_YOU_INTERESTED": "Jesteś zainteresowany?",
    "TATTOOS_PRICING_SUBTITLE": "Cena tatuażu zależy od wielkości, skomplikowania projektu i miejsca na ciele i jest ustalana po konsultacji z tatuatorem.",
    "HOW_DOES_IT_WORK": "Jak to działa?",

    "BOOKINGS_DESCRIPTION_TITLE_1": "Wybierz wzór",
    "BOOKINGS_DESCRIPTION_TITLE_2": "Wybierz tatuatora",
    "BOOKINGS_DESCRIPTION_TITLE_3": "Skontaktuj się z nami",
    "BOOKINGS_DESCRIPTION_TITLE_4": "Zarezerwuj termin",
    "BOOKINGS_DESCRIPTION_TITLE_5": "Zobacz swój projekt",
    "BOOKINGS_DESCRIPTION_TITLE_6": "Przyjdź na wizytę",

    "BOOKINGS_DESCRIPTION_1": "Możesz wybrać go z wolnych wzorów naszych tatuatorow, bądź wysłać nam inspiracje i tatuator zaprojektuje wzór specjalnie dla Ciebie",
    "BOOKINGS_DESCRIPTION_2": "Wybierz osobę której  stylistyka najbardziej Ci odpowiada lub jeśli nie możesz się zdecydować - napisz do nas a my zasugerujemy odpowiednia osobę",
    "BOOKINGS_DESCRIPTION_3": "Wyślij nam wiadomość przez social media lub na adres email. Wiadomość powinna zawierać: dokładny opis wymarzonej dziarki, kilka inspiracji, miejsce na ciele oraz wielkość tatuażu - mniej więcej w centymetrach",
    "BOOKINGS_DESCRIPTION_4": "Po ustaleniu powyższych informacji i wycenie projektu - pozostało już tylko zarezerowac termin. W tym celu pobieramy od każdego klienta zadatek, po którego wpłaceniu bookujemy termin",
    "BOOKINGS_DESCRIPTION_5": "Artyści przedstawiają projekt w dniu wykonywanej sesji. Wtedy jest też czas by wprowadzać do wzoru wszystkie poprawki, a nawet (jeśli będzie taka potrzeba) narysować go podstaw razem z Tobą",
    "BOOKINGS_DESCRIPTION_6": "Na wizytę staw się o ustalonej godzinie. Pamiętaj by minimum 24h przed tatuażem nie pic alkoholu, kawy i energetyków. Na wizytę najlepiej przyjdź wypoczęty, odpowiednio nawodniony i po spożyciu zbilansowanego posiłku",

    "CONTACT": "Kontakt",
    "TEXT_US": "lub\nnapisz\ndo nas",
    "CONTENT": "Treść",
    "NAME_AND_SURENAME": "Imię i nazwisko",
    "YOUR_EMAIL_ADDRESS": "Twój adres e-mail",

    "FORM_SUBMIT_SUCCESS": "Twoja wiadomość już do nas trafiła i wkrótce na nia odpowiemy!",
    "FORM_SUBMIT_FAILURE": "Niestety, nie udało się wysłać wiadomości, spróbuj ponownie później.",
    "SEND": "Wyślij",

    "CONTACT_EMAIL": "kontakt@lesnyszept.com",
    "CONTACT_PHONE_NUMBER": "506 232 303",
    "CONTACT_ADDRESS": "ul. Bracka 23/46, 00-028 Warszawa",

    "WHERE_TO_FIND_US": "Gdzie nas znaleźć?",

    // ARTISTS
    // AXELLENT
    "AXELLENT_NAME": "Axellent",
    "AXELLENT_DESCRIPTION": "Jestem Axellent - twój autystyczny i niebinarny tatuator (xD).\n" +
        "Tatuuję głównie w tematyce związanej z anime/kreskówkami, chociaż chętnie też, od czasu do czasu, robię inne rzeczy. " +
        "Dziaram zarówno w kolorze jak i w czerni, w zależności od preferencji! Bardzo chętnie stworzę z wami coś unikalnego, " +
        "zapraszam do zapisów! Gwarantuję niezapomniane uczucia (dlatego, że jestem zajebistą osobą, no scam, 100% legit).",

    "AXELLENT_PROFILE_ALT": "Osoba tatuujaca axellent",
    "AXELLENT_PORTFOLIO_1_ALT": "Tatuaz Ash Lynx z Banana Fish",
    "AXELLENT_PORTFOLIO_2_ALT": "Tatuaz Asuka z Neon Genesis Evangelion",
    "AXELLENT_PORTFOLIO_3_ALT": "Tatuaz Czaszka wilka z dolku w lesie",
    "AXELLENT_PORTFOLIO_4_ALT": "Tatuaz Draken z Tokyo Revengers",
    "AXELLENT_PORTFOLIO_5_ALT": "Tatuaz EVA z Evangelion",
    "AXELLENT_PORTFOLIO_6_ALT": "Tatuaz Gojo Satoru z Jujutsu Kaisen",
    "AXELLENT_PORTFOLIO_7_ALT": "Tatuaz Itachi z Naruto",
    "AXELLENT_PORTFOLIO_8_ALT": "Tatuaz Kafka z Honkai Star Rail",
    "AXELLENT_PORTFOLIO_9_ALT": "Tatuaz Kaito z Vocaloid",
    "AXELLENT_PORTFOLIO_10_ALT": "Tatuaz Wriothesley z Genshin Impact",

    // CYZANA
    "CYZANA_NAME": "Cyzana",
    "CYZANA_DESCRIPTION": "Jestem Cyza i chociaż z wykształcenia ryje w ziemi, obecnie preferuje ryć w skórze." +
        " Uczyłam się tatuować pod czujnym okiem Zoyi i cały czas doskonalę umiejętności. Pracuję w lineworku, czasem " +
        "dodam kolorowy akcent. Jeśli szukacie ciekawego sposobu na tatuaż swojego zwierzaka to dobrze trafiliście! " +
        "Tworzę również naklejki, które możecie dorwać w studio. Prywatnie jestem kocią mamą i gram w gierki.",

    "CYZANA_PROFILE_ALT": "tatuatorka cyzana",
    "CYZANA_PORTFOLIO_2_ALT": "tatuaz chomika",
    "CYZANA_PORTFOLIO_3_ALT": "tatuaz karpi",
    "CYZANA_PORTFOLIO_4_ALT": "tatuaz kota",
    "CYZANA_PORTFOLIO_5_ALT": "tatuaz kozy",
    "CYZANA_PORTFOLIO_6_ALT": "tatuaz misia",
    "CYZANA_PORTFOLIO_7_ALT": "tatuaz swieczki",

    // GABI
    "GABI_NAME": "Gabi",
    "GABI_DESCRIPTION": "Hejka, jestem Gabi. Swoją przygodę z piercingiem dopiero zaczynam, jednak samo zainteresowanie tym " +
        "tematem pojawiło się u mnie już jak byłam dzieckiem. Bardzo się cieszę, że mogę spełniać się w tej pasji " +
        "i obiecuję, że dołożę starań, aby każdy z Was czuł się u mnie komfortowo:) Poza piercingiem ważną rolę " +
        "w moim życiu odgrywają zwierzęta, muzyka gotycka oraz sztuka",

    "GABI_PROFILE_ALT": "piercerka gabi",
    "GABI_PORTFOLIO_1_ALT": "kolczyk bridge i septum",
    "GABI_PORTFOLIO_2_ALT": "kolczyk conch i rook",
    "GABI_PORTFOLIO_3_ALT": "kolczyk daith i upper lobe",
    "GABI_PORTFOLIO_4_ALT": "kolczyk helix i conch",
    "GABI_PORTFOLIO_5_ALT": "kolczyk septum i labret",
    "GABI_PORTFOLIO_6_ALT": "kolczyk septum i vertical labret",
    "GABI_PORTFOLIO_7_ALT": "kolczyk upper lobe i forward helix",
    "GABI_PORTFOLIO_8_ALT": "przeklocie jezyka",

    // MIO
    "MIO_NAME": "Mio",
    "MIO_DESCRIPTION": "Hej. Jestem Miodusa.\n" +
        "Tatuażystka z zamiłowaniem do mrocznego surrealizmu. Praktykuję sztukę tatuowania od kilku miesięcy, a moją techniką jest " +
        "cieniowanie przy użyciu metody whipshading. W wolnych chwilach gram w RPGi, jem, pizzę i tworzę kości do DnD. " +
        "Zapraszam do zapoznania się z moją twórczością.",

    "MIO_PROFILE_ALT": "tatuatorka mio",
    "MIO_PORTFOLIO_1_ALT": "tatuaz dwuglowego krolika",
    "MIO_PORTFOLIO_2_ALT": "tatuaz kobiety z glowa czaszki jelenia",
    "MIO_PORTFOLIO_3_ALT": "tatuaz kwiatek w doniczce z czaszki",
    "MIO_PORTFOLIO_4_ALT": "tatuaz plam u psychologa",
    "MIO_PORTFOLIO_5_ALT": "tatuaz ptaka z czaszka zamiast glowy",
    "MIO_PORTFOLIO_6_ALT": "tatuaz szczura z telefonem",

    // PALMER
    "PALMER_NAME": "Palmer",
    "PALMER_DESCRIPTION": "Jeśli jesteś palladynem, klerykiem, nekromanta, pol elfem, noise marines, albo mrocznym kurierem z bobasem " +
        "w klimatach post apo (death stranding) lub kimkolwiek kogo sobie dusza gracza wymarzy, to high five, już sie dogadamy! " +
        "Robię rycinowe tatuaże inspirowane grami, emblematami zakonów warhammera, bronią, zbrojami, ikonami zaklęć. " +
        "Dodatkowo jako fanka horrorów klasy z i horrorów ogólnie zajmuje się mrocznym graficznym surrealizmem (dużo czerni, dużo dotworku). " +
        "Połączenie oka, zębów i nóg pająka? Syrena bez głowy ze skrzydłami i poszarpanym ogonem? Zapraszam serdecznie, im dziwniej tym lepiej 🦇",

    "PALMER_PROFILE_ALT": "tatuazystka palmer",
    "PALMER_PORTFOLIO_1_ALT": "tatuaz nozyczek",
    "PALMER_PORTFOLIO_2_ALT": "tatuaz serca",


    // SAMOLECZENIE
    "SAMOLECZENIE_NAME": "Bartek",
    "SAMOLECZENIE_DESCRIPTION": "Cześć, jestem Bartek! " +
        "Moja przygoda z tatuażami zaczęła się 5 lat temu w momencie zrobienia sobie pierwszego krzywego napisu na nodze. " +
        "Teraz zajmuje się surrealizmem oraz przenoszeniem obrazków na skórę. " +
        "Jeśli podoba Ci się mój styl, umów się do mnie po swój tatuaż :D ",

    "SAMOLECZENIE_PROFILE_ALT": "tatuazysta samoleczenie",
    "SAMOLECZENIE_PRORTFOLIO_1_ALT": "abstrakcyjny tatuaz oczu",
    "SAMOLECZENIE_PRORTFOLIO_2_ALT": "tatuaz kobiety ze skrzydlami wazki z glowy",
    "SAMOLECZENIE_PRORTFOLIO_3_ALT": "tatuaz nozyczki z motylem",
    "SAMOLECZENIE_PRORTFOLIO_4_ALT": "tatuaz nozyczki z okiem",
    "SAMOLECZENIE_PRORTFOLIO_5_ALT": "tatuaz obrazu beksinskiego 2",
    "SAMOLECZENIE_PRORTFOLIO_6_ALT": "tatuaz obrazu beksinskiego",
    "SAMOLECZENIE_PRORTFOLIO_7_ALT": "tatuaz pocaunku",


    // TALA
    "TALA_NAME": "Tala",
    "TALA_DESCRIPTION": "Heloł!\n" +
        "W tatuatorskim środowisku znacie mnie jako Talinka, ale możecie mówić mi Tala. \n" +
        "Jestem po kursie tatuażu pod okiem Zoyi i wciąż się uczę. Tatuuję głównie w linearcie (styl składający się głównie z linii) " +
        "czasem z małymi akcentami koloru. Mimo, że moja przygoda z tatuowaniem nie trwa długo  to jestem pewna, że razem wyczarujemy " +
        "nie tylko magiczne tatuaże, ale także wspomnienia!",

    "TALA_PROFILE_ALT": "tatuazystka tala",
    "TALA_PORTFOLIO_1_ALT": "tatuaz glowa",
    "TALA_PORTFOLIO_2_ALT": "tatuaz linearny skorpion.webp",
    "TALA_PORTFOLIO_3_ALT": "tatuaz lineart kot",
    "TALA_PORTFOLIO_4_ALT": "tatuaz napis",
    "TALA_PORTFOLIO_5_ALT": "tatuaz znaczek pocztowy",
    "TALA_PORTFOLIO_6_ALT": "abstrakcyjny tatuaz motyl",


    // ZOYA
    "ZOYA_NAME": "Zoya",
    "ZOYA_DESCRIPTION": "Cześc, jestem zoya i od kilku lat zajmuje się tatuażem. " +
        "Mój styl to połączenie dotworku i sketchu z delikatną kreską, najlepiej w mrocznym wydaniu. " +
        "Prócz dziarania realizuje się twórczo poprzez custom - maluje po butach, kurtkach i innych przedmiotach, które wpadną mi w ręce. Kilka takich prac możecie zobaczyć w studio ;) " +
        "Prywatnie psia i ślimacza mama, weganka z 9-letnim stażem.",

    "ZOYA_PROFILE_ALT": "tatuazystka zoya",
    "ZOYA_PORTFOLIO_1_ALT": "tatuaz cma z kwiatami na klatce",
    "ZOYA_PORTFOLIO_2_ALT": "tatuaz cmy na brzuchu",
    "ZOYA_PORTFOLIO_3_ALT": "tatuaz czaszka z grzybami",
    "ZOYA_PORTFOLIO_4_ALT": "tatuaz katedra na plecach",
    "ZOYA_PORTFOLIO_5_ALT": "tatuaz krolikow z rogami",
    "ZOYA_PORTFOLIO_6_ALT": "tatuaz kruka",
    "ZOYA_PORTFOLIO_7_ALT": "tatuaz miecz na klatce",
    "ZOYA_PORTFOLIO_8_ALT": "tatuaz motyl na klatce",
    "ZOYA_PORTFOLIO_9_ALT": "tatuaz noga lalki",
    "ZOYA_PORTFOLIO_10_ALT": "tatuaz oka ze skrzydalmi",
    "ZOYA_PORTFOLIO_11_ALT": "tatuaz osmiornicy",
    "ZOYA_PORTFOLIO_12_ALT": "tatuaz smoka",


    // JULKA
    "JULKA_NAME": "Julia",
    "JULKA_DESCRIPTION": "Jestem Julia, czyli julczyy_piercing ❤️\n" +
        "Oficjalnie piercingiem zajmuje się od października 2022 roku i od tego czasu, niezmiennie możecie spotkać mnie w Leśny Szepcie!\n" +
        "Piercing to moja pasja, chętnie podejmuje się wyzwań jakie mnie w nim spotykają, takich jak trudne anatomie czy spersonalizowane customy, więc nie bójcie się prosić o coś bardziej oryginalnego!\n" +
        "Zapraszam was serdecznie!\n" +
        "Stwórzmy razem coś pięknego ❤️",

    'JULKA_PROFILE_ALT': "zdjecie piercerki julczyy",
    'JULKA_GALLERY_1_ALT': "angel fangs bridge i septum",
    'JULKA_GALLERY_2_ALT': "bridge piercing",
    'JULKA_GALLERY_3_ALT': "custom industrial piercing",
    'JULKA_GALLERY_4_ALT': "dwa vertical labrety",
    'JULKA_GALLERY_5_ALT': "industrial piercing",
    'JULKA_GALLERY_6_ALT': "lobe daith flat i forward helix",
    'JULKA_GALLERY_7_ALT': "micro dermale",
    'JULKA_GALLERY_8_ALT': "piercing brzucha",
    'JULKA_GALLERY_9_ALT': "piercing sutkow",
    'JULKA_GALLERY_10_ALT': "potrojny nostril",
    'JULKA_GALLERY_11_ALT': "ufo piercing",

    // IMAGE ALT
    "WEBRANCE_LOGO_ALT": "Logo firmy webrance",

    "GALLERY_PICTURE_1_ALT": "Tatuaz smoka",
    "GALLERY_PICTURE_2_ALT": "Tatuaz Itachi z Naruto",
    "GALLERY_PICTURE_3_ALT": "Tatuaz oczy ze skrzydlami",
    "GALLERY_PICTURE_4_ALT": "Tatuaz nozyczki z motylem",
    "GALLERY_PICTURE_5_ALT": "Tatuaz ptaka z dwoma glowami",
    "GALLERY_PICTURE_6_ALT": "Voucher lesny szept",
    "GALLERY_PICTURE_7_ALT": "Kolczyk bridge i septum",

    "PL_ALT": "Flaga polski",
    "EN_ALT": "Flaga Wielkiej Brytani",

    "LOGO_SQUARE_ALT": "Logo studia Leśny Szept",
    "LOGO_RECTANGLE_ALT": "Logo studia Leśny Szept z napisem",

    "PALETTE_ALT": "Paletka do malowania",
    "TATTOO_MACHINE_ALT": "Maszynka do tatuażu",
    "MASK_ALT": "Maseczka",

    "FACEBOOK_LINK_ARIA_LABEL": "Profil na facebooku studia Leśny Szept",
    "INSTAGRAM_LINK_ARIA_LABEL": "Profil na instagramie studia Leśny Szept",
    "GOOGLE_MAPS_TITLE": "Leśny Szept mapa dojazdu do studia",

    "WALK_IN_ALT": "Walk in day Wiosenny",

    "REGULAMIN": "Regulamin",
}
