import React, {FC, useEffect, useState} from "react";
import {StyledMobileMenu} from "./MobileMenu.style";
import {Logo} from "../logo/Logo.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {menuRoutes} from "../service/Menu.service";
import {MenuItem} from "../menuItem/MenuItem.component";

interface MobileMenuProps {
    selectedPageName?: string;
}

export const MobileMenu: FC<MobileMenuProps> = (props: MobileMenuProps) => {
    const [menuVisible, setMenuVisible] = useState(false);

    useEffect(()=>{
       return () => document.body.classList.remove("stop-scrolling")
    }, []);

    const toggleScrollDisabled = (shouldDisable: boolean) => {
        if (shouldDisable) {
            window.scrollTo(0, 0);
            document.body.classList.add("stop-scrolling");
        } else {
            document.body.classList.remove("stop-scrolling");
        }
    }

    const toggleMenuVisible = () => {
        setMenuVisible(prevState => {
            toggleScrollDisabled(!prevState);
            return !prevState;
        });
    }

    const isSelected = (pageName: string): boolean => {
        return props.selectedPageName === pageName;
    }

    return <StyledMobileMenu menuVisible={menuVisible}>
        <div className={"row"}>
            <div className={"col-xs-10 col-sm-10 col-md-11 col-lg-11"}>
                <Logo/>
            </div>
            <div className={"col-xs-2 col-sm-2 col-md-1 col-lg-1"}>
                <FontAwesomeIcon icon={faBars} onClick={toggleMenuVisible}/>
            </div>
        </div>
        {menuVisible && <div className={"row end-xs end-sm end-md"}>
            <nav>
                <ul>
                    {menuRoutes.slice(0, 4).map(value =>
                        <MenuItem
                            key={value.pathName}
                            label={value.label}
                            isSelected={isSelected(value.pathName)}
                            link={value.link}
                            onClick={toggleMenuVisible}
                        />)}
                </ul>
            </nav>
        </div>}
    </StyledMobileMenu>;
};
