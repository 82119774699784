import styled from "styled-components";
import {XS_MAX_SIZE} from "../../util/ViewSizeUtils";

export const StyledDesignedByWebrance = styled.div`
  .by-webrance-label {
    padding-bottom: 5px;
    text-align: center;
    font-family: Mendl Sans Dawn;
    color: #A1A1A1;
    font-size: 20px;
  }

  .webrance-logo {
    opacity: 50%;
    text-align: center;
    max-width: 250px;
    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      padding-bottom: 20px;
    }
  }

  .img {
    width: 150px;
  }
`;
