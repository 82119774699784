export const EN_MESSAGES: any = {
    "HOME_PAGE_TITLE": "Tattoo & Piercing",
    "HOME_PAGE_DESCRIPTION": "A place where the unique atmosphere and beauty of the forest meets the modernity and functionality of the city center.",
    "PAGE_NOT_FOUND": "Page not found",
    "VIEW_ARTISTS": "View artists",
    "VIEW_MORE": "View more",
    "MAIN_PAGE": "Home page",
    "ARTISTS": "Artists",
    "TATTOOS": "Tattoos",
    "PIERCING": "Piercing",
    "BOOKINGS": "Booking",
    "PRICING": "Pricing",
    "ABOUT_US": "About us",
    "ABOUT_US_FIRST_BLOCK": "Experience the unique atmosphere and beauty of the forest at Lesny Szept, the modern, functional tattoo and piercing studio in Warsaw. We pride ourselves on personalized customer service, precision, and creativity. Our tattoos are one-of-a-kind, perfectly tailored to your anatomy. Designs are entirely created by our talented team of artists, taking into account your individual needs and preferences.",
    "ABOUT_US_SECOND_BLOCK": "Our studio is made up of exceptional, ambitious, creative, and meticulous artists who are passionate about their work. What makes us unique is the high sense of aesthetics and the original perspective in art.",
    "ABOUT_US_THIRD_BLOCK": "We take sterilization and client care very seriously and ensure that our studio is always hygienic. We will also provide you with detailed aftercare instructions to ensure the longevity of your tattoo. Our help and support do not stop after you leave the studio, we are always willing to resolve your concerns and answer your questions.",
    "GALLERY": "Gallery",
    "BY_WEBRANCE": "Designed and developed by",
    "LESNY_SZEPT": "Leśny Szept",
    "ADDRESS_STREET": "st.  Bracka 23/46,",
    "ADDRESS_CITY": "00-020 Warszawa",

    //PRICING
    "MINIMAL_PRICE": "Minimum price of the studio",
    "SMALL_TATTOO": "Small tattoo",
    "WHOLE_DAY_SESSION": "Whole day session",

    "MINIMAL_PRICE_PRICE": "300zł",
    "SMALL_TATTOO_PRICE": "450-800zł",
    "WHOLE_DAY_SESSION_PRICE": "1500zł",
    "CHOKING_PRICE": "od 50zł",

    "LOBE": "Lobe",
    "2_LOBE": "2x Lobe",
    "HELIX": "Helix",
    "FORWARD_HELIX": "Forward helix",
    "VERTICAL_HELIX": "Vertical helix",
    "FLAT": "Flat",
    "CONCH": "Conch",
    "DAITH": "Daith",
    "ROOK": "Rook",
    "SNUG": "Snug",
    "TRAGUS": "Tragus",
    "ANTI_TRAGUS": "Anti tragus",
    "INDUSTRIAL": "Industrial",
    "ORBITAL": "Orbital",
    "RAGNAR": "Ragnar",
    "LEFT_NIPPLE": "Left nipple",
    "RIGHT_NIPPLE": "Right nipple",
    "BOTH_NIPPLES": "Both nipples",
    "NOSTRIL": "Nostril",
    "SEPTUM": "Septum",
    "BRIDGE": "Bridge",
    "MANTIS": "Mantis",
    "LABRET": "Labret",
    "MONROE": "Monroe",
    "MADONNA": "Madonna",
    "SMILEY": "Smiley",
    "MEDUSA": "Medusa",
    "JESTRUM": "Jestrum",
    "BITES": "Bites",
    "ASHLEY": "Ashley",
    "STANDARD_TONGUE": "Standard tongue",
    "VENOM": "Venom",
    "NAVEL": "Navel",
    "REVERSE_NAVEL": "Reverse navel",
    "BREW_PIERCING": "Eyebrow piercing",
    "OUR_CONSULTATION": "Service consultation performed at Leśny Szept",
    "BAD_CLIENT_CONSULTATION": "Consultation after not following recommendations",
    "OTHER_PIERCER_CONSULTATION": "Consultation for a service performed at another studio",
    "CHANGE_PIERCING": "Earring replacement",
    "OTHER_PIERCER_CHANGE_PIERCING": "Replacement of a piercing done at another studio",

    "LOBE_PRICE": "120zł",
    "2_LOBE_PRICE": "180zł",
    "HELIX_PRICE": "130zł",
    "FORWARD_HELIX_PRICE": "130zł",
    "VERTICAL_HELIX_PRICE": "130zł",
    "FLAT_PRICE": "130zł",
    "CONCH_PRICE": "130zł",
    "DAITH_PRICE": "150zł",
    "ROOK_PRICE": "140zł",
    "SNUG_PRICE": "150zł",
    "TRAGUS_PRICE": "130zł",
    "ANTI_TRAGUS_PRICE": "140zł",
    "INDUSTRIAL_PRICE": "150zł",
    "ORBITAL_PRICE": "170zł",
    "RAGNAR_PRICE": "150zł",

    "LEFT_NIPPLE_PRICE": "150zł",
    "RIGHT_NIPPLE_PRICE": "150zł",
    "BOTH_NIPPLES_PRICE": "280zł",
    "NOSTRIL_PRICE": "130zł",
    "SEPTUM_PRICE": "120zł",
    "BRIDGE_PRICE": "130zł",
    "MANTIS_PRICE": "220zł",

    "LABRET_PRICE": "120zł",
    "MONROE_PRICE": "120zł",
    "MADONNA_PRICE": "120zł",
    "MEDUSA_PRICE": "120zł",
    "JESTRUM_PRICE": "120zł",
    "SMILEY_PRICE": "120zł",
    "BITES_PRICE": "240zł",
    "ASHLEY_PRICE": "120zł",
    "STANDARD_TONGUE_PRICE": "150zł",
    "VENOM_PRICE": "250zł",

    "NAVEL_PRICE": "140zł",
    "REVERSE_NAVEL_PRICE": "140zł",

    "BREW_PIERCING_PRICE": "130zł",

    "OUR_CONSULTATION_PRICE": "Za darmo",
    "OTHER_PIERCER_CHANGE_PIERCING_PRICE": "50zł",
    "BAD_CLIENT_CONSULTATION_PRICE": "80zł",
    "OTHER_PIERCER_CONSULTATION_PRICE": "50zł",
    "CHANGE_PIERCING_PRICE": "Cena kolczyka",

    "TATTOS": "Tattoos",
    "EAR": "Ear",
    "NIPPLES": "Nipples",
    "NOSE": "Nose",
    "LIPS": "Lips",
    "TONGUE": "Tongue",
    "BELLY_BUTTON": "Belly button",
    "EYEBROW": "Eyebrow",
    "OTHER": "Other",

    "BORDER_COMPOSITION": "Border",
    "BUTTERFLY_COMPOSITION": "Butterfly",
    "FLOWER_COMPOSITION": "Flowere",
    "CROSS_COMPOSITION": "Cross",
    "PAW_COMPOSITION": "Paw",
    "DISCO_COMPOSITION": "Disco Ball",

    "BORDER_COMPOSITION_PRICE": "280zł",
    "BUTTERFLY_COMPOSITION_PRICE": "180zł",
    "FLOWER_COMPOSITION_PRICE": "250zł",
    "CROSS_COMPOSITION_PRICE": "250zł",
    "PAW_COMPOSITION_PRICE": "290zł",
    "DISCO_COMPOSITION_PRICE": "350zł",

    "TOOTH_GEMS": "Tooth gems",
    "TOOTH_GEMS_CUSTOM": "Tooth gems",
    "REMOVING_TOOTH_GEMS": "Removing",

    "ONE_GEM": "1 crystal",
    "TWO_GEMS": "2 crystals",
    "THREE_GEMS": "3 crystals",
    "FOUR_GEMS": "4 crystals",
    "FIVE_SIX_GEMS": "5-6 crystals",
    "SEVEN_ELEVEN_GEMS": "7-11 crystals",
    "ELEVEN_FIFTEEN_GEMS": "12-15 crystals",
    "ANOHTER_GEM": "Each next",

    "ONE_GEM_PRICE": "70zł",
    "TWO_GEMS_PRICE": "120zł",
    "THREE_GEMS_PRICE": "170zł",
    "FOUR_GEMS_PRICE": "210zł",
    "FIVE_SIX_GEMS_PRICE": "250zł",
    "SEVEN_ELEVEN_GEMS_PRICE": "280zł",
    "ELEVEN_FIFTEEN_GEMS_PRICE": "300zł",
    "ANOHTER_GEM_PRICE": "10zł",

    "OUR_ONE_SIX_GEMS_REMOVING": "1-6 crystals performed at Leśny Szept",
    "OUR_SEVEN_PLUS_GEMS_REMOVING": "7+ crystals performed at Leśny Szept",
    "SOMEONE_ONE_SIX_GEMS_REMOVING": "1-6 crystals performed at another studio",
    "SOMEONE_ELSE_SEVEN_PLUS_GEMS_REMOVING": "7+ crystals performed at another studio",

    "OUR_ONE_SIX_GEMS_REMOVING_PRICE": "60zł",
    "OUR_SEVEN_PLUS_GEMS_REMOVING_PRICE": "100zł",
    "SOMEONE_ONE_SIX_GEMS_REMOVING_PRICE": "80zł",
    "SOMEONE_ELSE_SEVEN_PLUS_GEMS_REMOVING_PRICE": "120zł",

    //PRICING END

    "ARE_YOU_INTERESTED": "Are you interested?",
    "TATTOOS_PRICING_SUBTITLE": "The cost of a tattoo depends on its size, complexity of the design, location on the body and is determined after consultation with a tattoo artist.",
    "HOW_DOES_IT_WORK": "How does it work?",

    "BOOKINGS_DESCRIPTION_TITLE_1": "Choose project",
    "BOOKINGS_DESCRIPTION_TITLE_2": "Choose tattoo artist",
    "BOOKINGS_DESCRIPTION_TITLE_3": "Contact us",
    "BOOKINGS_DESCRIPTION_TITLE_4": "Book an appointment",
    "BOOKINGS_DESCRIPTION_TITLE_5": "Confirm your project",
    "BOOKINGS_DESCRIPTION_TITLE_6": "Visit us",

    "BOOKINGS_DESCRIPTION_1": "You can choose from the available patterns of our tattoo artists or send us your inspiration and the tattoo artist will design a pattern specifically for you",
    "BOOKINGS_DESCRIPTION_2": "Choose the artist whose style you like the most or if you have any doubts, let us know and we will choose the right person together",
    "BOOKINGS_DESCRIPTION_3": "Text us via social media or an e-mail. The message should contain a detailed description of the desired tattoo, initial ideas, the location on the body, and the approximate size of the tattoo in centimeters",
    "BOOKINGS_DESCRIPTION_4": "If we come to a mutual conclusion, all that's left is to make an appointment. After we receive a down payment from you, we can set a date for the meeting.",
    "BOOKINGS_DESCRIPTION_5": "Final design of the tattoo is presented on the session’s day. It is the time when you can make any adjustments, or design it from the scratch, if necessary.",
    "BOOKINGS_DESCRIPTION_6": "Make sure not to consume any alcohol, caffeine, or energy drinks at least 24 hours before the visit. Before the session, you should relax, hydrate yourself and eat a balanced meal. Punctuality is also very essential for us, so please be on time!",

    "CONTACT": "Contact us",
    "TEXT_US": "or\ntext\nus",
    "CONTENT": "Content",
    "NAME_AND_SURENAME": "Name and surname",
    "YOUR_EMAIL_ADDRESS": "Your email address",

    "FORM_SUBMIT_SUCCESS": "Your message has already reached us and we will respond to it soon!",
    "FORM_SUBMIT_FAILURE": "Unfortunately, the message was not sent, please try again later.",
    "SEND": "Send",

    "CONTACT_EMAIL": "kontakt@lesnyszept.com",
    "CONTACT_PHONE_NUMBER": "506 232 303",
    "CONTACT_ADDRESS": "st. Bracka 23/46, 00-028 Warsaw",

    "WHERE_TO_FIND_US": "Find us",

    // ARTISTS
    // AXELLENT
    "AXELLENT_NAME": "Axellent",
    "AXELLENT_DESCRIPTION": "I'm Axellent - your autistic and non-binary tattoo artist (xD). I mainly tattoo in the theme of anime/cartoons, although" +
        " I'm also happy to do other things from time to time. I tattoo both in color and black, depending on preferences! " +
        "I'm very eager to create something unique with you, so feel free to book an appointment! I guarantee unforgettable experiences " +
        "(because I'm an awesome person, no scam, 100% legit).",

    "AXELLENT_PROFILE_ALT": "Tattoo artist axellent tattoo",
    "AXELLENT_PORTFOLIO_1_ALT": "Ash Lynx from Banana Fish tattoo",
    "AXELLENT_PORTFOLIO_2_ALT": "Asuka from Neon Genesis Evangelion tattoo",
    "AXELLENT_PORTFOLIO_3_ALT": "Czaszka wilka from dolku w lesie tattoo",
    "AXELLENT_PORTFOLIO_4_ALT": "Draken from Tokyo Revengers tattoo",
    "AXELLENT_PORTFOLIO_5_ALT": "EVA from Evangelion tattoo",
    "AXELLENT_PORTFOLIO_6_ALT": "Gojo Satoru from Jujutsu Kaisen tattoo",
    "AXELLENT_PORTFOLIO_7_ALT": "Itachi from Naruto tattoo",
    "AXELLENT_PORTFOLIO_8_ALT": "Kafka from Honkai Star Rail tattoo",
    "AXELLENT_PORTFOLIO_9_ALT": "Kaito from Vocaloid tattoo",
    "AXELLENT_PORTFOLIO_10_ALT": "Wriothesley from Genshin Impact tattoo",

    // CYZANA
    "CYZANA_NAME": "Cyzana",
    "CYZANA_DESCRIPTION": "I'm Cyza and although by education I dig in the ground, currently I prefer to dig in the skin. " +
        "I learned to tattoo under the watchful eye of Zoya and I'm constantly honing my skills. I work in line work, " +
        "sometimes adding a colorful accent. If you're looking for an interesting way to tattoo your pet, you've come to the right place!" +
        " I also create stickers that you can grab at the studio. Personally, I'm a cat mom and I enjoy playing games.",

    "CYZANA_PROFILE_ALT": "Cyzana tatoo artist",
    "CYZANA_PORTFOLIO_2_ALT": "hamster tattoo",
    "CYZANA_PORTFOLIO_3_ALT": "carp tattoo",
    "CYZANA_PORTFOLIO_4_ALT": "cat tattoo",
    "CYZANA_PORTFOLIO_5_ALT": "goat tattoo",
    "CYZANA_PORTFOLIO_6_ALT": "bear tattoo",
    "CYZANA_PORTFOLIO_7_ALT": "candle tattoo",

    // GABI
    "GABI_NAME": "Gabi",
    "GABI_DESCRIPTION": "Hey, I'm Gabi. I'm just starting my journey with piercing, but my interest in this topic appeared when I was a child." +
        " I'm very happy that I can pursue this passion and I promise to make every effort to make everyone feel comfortable with me :) " +
        "Besides piercing, animals, gothic music, and art play an important role in my life.",

    "GABI_PROFILE_ALT": "Gabi piercer",
    "GABI_PORTFOLIO_1_ALT": "bridge and septum piercing",
    "GABI_PORTFOLIO_2_ALT": "conch and rook piercing",
    "GABI_PORTFOLIO_3_ALT": "daith and upper lobe piercing",
    "GABI_PORTFOLIO_4_ALT": "helix and conch piercing",
    "GABI_PORTFOLIO_5_ALT": "septum and labret piercing",
    "GABI_PORTFOLIO_6_ALT": "septum and vertical labret piercing",
    "GABI_PORTFOLIO_7_ALT": "upper lobe and forward helix piercing",
    "GABI_PORTFOLIO_8_ALT": "tongue piercing",

    // MIO
    "MIO_NAME": "Mio",
    "MIO_DESCRIPTION":
        "Hey. I'm Miodusa. \n" +
        "A tattoo artist with a passion for dark surrealism. I've been practicing the art of tattooing for a few months now, " +
        "and my technique involves shading using the whipshading method. In my free time, I play RPGs, eat pizza, and create DnD dice." +
        " Feel free to check out my work.",

    "MIO_PROFILE_ALT": "Mio tattoo artist",
    "MIO_PORTFOLIO_1_ALT": "dwo headed rabbit tattoo",
    "MIO_PORTFOLIO_2_ALT": "woman with deer skull head tattoo",
    "MIO_PORTFOLIO_3_ALT": "flower in skull pot tatoo",
    "MIO_PORTFOLIO_4_ALT": "inkblots tattoo",
    "MIO_PORTFOLIO_5_ALT": "bird with skull head tattoo",
    "MIO_PORTFOLIO_6_ALT": "rat with phone head tattoo",

    // PALMER
    "PALMER_NAME": "Palmer",
    "PALMER_DESCRIPTION": "If you're a paladin, cleric, necromancer, half-elf, noise marines, or a dark courier with a " +
        "baby in post-apocalyptic vibes (Death Stranding) or anyone else your gamer soul desires, high five, we'll figure it out!" +
        " I create engraved tattoos inspired by games, Warhammer emblems, weapons, armor, and spell icons. Additionally, as a fan of classic and general horror, " +
        "I delve into dark graphic surrealism (lots of black, lots of dotwork). A combination of an eye, teeth, and spider legs? A headless mermaid with wings and a jagged tail?" +
        " You're warmly welcome, the weirder, the better 🦇",

    "PALMER_PROFILE_ALT": "Palmer tattoo artist",
    "PALMER_PORTFOLIO_1_ALT": "scizors tattoo",
    "PALMER_PORTFOLIO_2_ALT": "heart tatoo",


    // SAMOLECZENIE
    "SAMOLECZENIE_NAME": "Bartek",
    "SAMOLECZENIE_DESCRIPTION":
        "Hi, I'm Bartek! My journey with tattoos began 5 years ago when I got my first crooked inscription on my leg. " +
        "Now I specialize in surrealism and transferring images onto skin. " +
        "If you like my style, book an appointment with me for your tattoo :D",

    "SAMOLECZENIE_PROFILE_ALT": "samoleczenie tattoo artist",
    "SAMOLECZENIE_PRORTFOLIO_1_ALT": "abstract eye tattoo",
    "SAMOLECZENIE_PRORTFOLIO_2_ALT": "tattoo of woman with wings from head",
    "SAMOLECZENIE_PRORTFOLIO_3_ALT": "scizors with butterfly tattoo",
    "SAMOLECZENIE_PRORTFOLIO_4_ALT": "scizors with eye tattoo",
    "SAMOLECZENIE_PRORTFOLIO_5_ALT": "beksinski painting tattoo 2",
    "SAMOLECZENIE_PRORTFOLIO_6_ALT": "beksinski painting tattoo",
    "SAMOLECZENIE_PRORTFOLIO_7_ALT": "kiss tattoo",


    // TALA
    "TALA_NAME": "Tala",
    "TALA_DESCRIPTION": "Heloł!\n" +
        "In the tattooing community, you know me as Talinka, but you can call me Tala. I completed a tattoo course under the guidance of Zoya" +
        " and I'm still learning. I mainly tattoo in line work (a style mainly composed of lines) sometimes with small color accents. Although " +
        "my journey with tattooing hasn't been long, I'm confident that together we will create not only magical tattoos but also memories!",

    "TALA_PROFILE_ALT": "Tala tattoo artist",
    "TALA_PORTFOLIO_1_ALT": "head tattoo",
    "TALA_PORTFOLIO_2_ALT": "lineart scorptio tattoo",
    "TALA_PORTFOLIO_3_ALT": "lineart cat tattoo",
    "TALA_PORTFOLIO_4_ALT": "inscription tattoo",
    "TALA_PORTFOLIO_5_ALT": "postage stamp tattoo",
    "TALA_PORTFOLIO_6_ALT": "abstract butterfly tattoo",


    // ZOYA
    "ZOYA_NAME": "Zoya",
    "ZOYA_DESCRIPTION": "Hi, I'm Zoya and I've been doing tattoos for several years. My style combines dotwork and sketch with " +
        "delicate lines, preferably in a dark rendition. Apart from tattooing, I express myself creatively through custom work - painting on shoes, jackets, " +
        "and other items that come into my hands. You can see some of these works in the studio ;) " +
        "Personally, I'm a dog and snail mom, a vegan with a 11-year experience.",

    "ZOYA_PROFILE_ALT": "Photo of zoya tattoo artist",
    "ZOYA_PORTFOLIO_1_ALT": "moth with flowers on chest tattoo",
    "ZOYA_PORTFOLIO_2_ALT": "moth tattoo",
    "ZOYA_PORTFOLIO_3_ALT": "skull with mushrooms tattoo",
    "ZOYA_PORTFOLIO_4_ALT": "cathedral tattoo on back",
    "ZOYA_PORTFOLIO_5_ALT": "rabits with horns tattoo",
    "ZOYA_PORTFOLIO_6_ALT": "raven tattoo",
    "ZOYA_PORTFOLIO_7_ALT": "sword on chest tattoo",
    "ZOYA_PORTFOLIO_8_ALT": "butterfly tattoo on chest",
    "ZOYA_PORTFOLIO_9_ALT": "doll leg tattoo",
    "ZOYA_PORTFOLIO_10_ALT": "eye with wings tattoo",
    "ZOYA_PORTFOLIO_11_ALT": "octopus tattoo",
    "ZOYA_PORTFOLIO_12_ALT": "tattoo of dragon",


    // JULKA
    "JULKA_NAME": "Julia",
    "JULKA_DESCRIPTION": "I am Julia, also known as julczyy_piercing ❤️\n " +
        "Officially, I've been doing piercings since October 2022, and since then, you can always find me at Leśny Szept!\n" +
        "Piercing is my passion, I willingly take on challenges that come with it, such as difficult anatomies or personalized customs, so don't hesitate to ask for something more original!\n" +
        "I warmly invite you!\n" +
        "Let's create something beautiful together ❤️",

    'JULKA_PROFILE_ALT': "photo of piercerki julczyy",
    'JULKA_GALLERY_1_ALT': "angel fangs bridge and septum",
    'JULKA_GALLERY_2_ALT': "bridge piercing",
    'JULKA_GALLERY_3_ALT': "custom industrial piercing",
    'JULKA_GALLERY_4_ALT': "two vertical labrety",
    'JULKA_GALLERY_5_ALT': "industrial piercing",
    'JULKA_GALLERY_6_ALT': "lobe daith flat and forward helix",
    'JULKA_GALLERY_7_ALT': "micro dermal",
    'JULKA_GALLERY_8_ALT': "belly piercing",
    'JULKA_GALLERY_9_ALT': "nipple piercing",
    'JULKA_GALLERY_10_ALT': "tripple nostril",
    'JULKA_GALLERY_11_ALT': "ufo piercing",

    // IMAGE ALT
    "WEBRANCE_LOGO_ALT": "Webrance company logo",

    "GALLERY_PICTURE_1_ALT": "Dragon tattoo",
    "GALLERY_PICTURE_2_ALT": "Itachi from Naruto tattoo",
    "GALLERY_PICTURE_3_ALT": "Eyes with wings tattoo",
    "GALLERY_PICTURE_4_ALT": "Scizors with butterfly tattoo",
    "GALLERY_PICTURE_5_ALT": "Two headed bird tattoo",
    "GALLERY_PICTURE_6_ALT": "Lesny Szept Voucher",
    "GALLERY_PICTURE_7_ALT": "Bridge and septum piercing",

    "PL_ALT": "Polish flag",
    "EN_ALT": "Great Britain flag",

    "LOGO_SQUARE_ALT": "Logo of Leśny Szept studio",
    "LOGO_RECTANGLE_ALT": "Logo of Leśny Szept studio with label napisem",

    "PALETTE_ALT": "Palette",
    "TATTOO_MACHINE_ALT": "Tattoo machine",
    "MASK_ALT": "Mask",

    "FACEBOOK_LINK_ARIA_LABEL": "Facebook profile of Leśny Szept studio",
    "INSTAGRAM_LINK_ARIA_LABEL": "Instagram profile of Leśny Szept studio",
    "GOOGLE_MAPS_TITLE": "Leśny Szept studio map",

    "WALK_IN_ALT": "Spring walk in day",

    "REGULAMIN": "Regulamin",
}
