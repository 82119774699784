import styled from "styled-components";
import {SM_MAX_SIZE} from "../../../util/ViewSizeUtils";

export const StyledDesktopMenu = styled.div`
  @media only screen and (max-width: ${SM_MAX_SIZE}px) {
    display: none;
  }

  font-size: 28.65px;
  margin: 0;

  ul {
    margin: 0;
    list-style-type: none;
    overflow: hidden;
    padding-left: 0;
  }
`;
