import styled from "styled-components";
import {XS_MAX_SIZE} from "../../util/ViewSizeUtils";

interface ModalContainerProps {
    isOpen: boolean;
}

export const ModalContainer = styled.div<ModalContainerProps>`
  font-size: 0;
  color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px !important;

  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    padding: 10px !important;
  }

  z-index: 3;
`;
