import * as React from "react"
import {MainLayout} from "./src/layout/main/MainLayout.component";

interface WrapPageElementProps {
    element: any;
    props: any;
}

const WrapPageElement = (props: WrapPageElementProps) => (
    <MainLayout pageContext={props.props.pageContext}>{props.element}</MainLayout>
)

export default WrapPageElement
