import * as React from "react";

export interface PageContext {
    locale?: string;
    dateFormat?: string;
    pageName?: string;
}

const defaultPageContext: PageContext = {};
export const LocaleContext = React.createContext(defaultPageContext);
export const LocaleContextProvider = LocaleContext.Provider;
