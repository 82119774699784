import styled from "styled-components";
import {MD_MIN_SIZE} from "../../../util/ViewSizeUtils";
import {Colors} from "../../../style/ColorUtils";

interface StyledMobileMenuProps {
  menuVisible: boolean;
}

export const StyledMobileMenu = styled.div<StyledMobileMenuProps>`
  @media only screen and (min-width: ${MD_MIN_SIZE}px) {
    display: none;
  }
  
  ${(props: StyledMobileMenuProps) => props.menuVisible 
      ? "backdrop-filter: blur(50px);\n " +
      "height: 100vh;\n  " +
      "position: absolute;\n  " +
      "top: 0;\n  " +
      "width: 100%;" 
      : ""};
  z-index: 10;
  text-align: end;

  svg {
    vertical-align: middle;
    padding-right: 20px;
    height: 100%;
    padding-top: 2px;
    width: 40px;
    color: ${Colors.YELLOW};
    
    @media only screen and (max-width: ${487}px) {
      vertical-align: middle;
      padding-right: 0;
      height: 30px;
      padding-top: 4px;
      width: unset;
    }
  }
  
  nav {
    width: 100%;
  }

  ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
    display: grid;
  }
  
  li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
