import React, {PropsWithChildren, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {CloseButton} from "./CloseButton.style";
import {ModalContainer} from "./ModalContainer.style";
import {ModalBackground} from "./ModalBackground.style";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
}

export const Modal: React.FC<PropsWithChildren<ModalProps>> = (props: PropsWithChildren<ModalProps>) => {
    useEffect(() => {
        if (props.isOpen) {
            document.body.classList.add("stop-scrolling");
        } else {
            document.body.classList.remove("stop-scrolling");
        }
        return () => document.body.classList.remove("stop-scrolling");
    }, [props.isOpen]);

    if (!props.isOpen) return <></>;

    return (
        <>
            <ModalBackground isOpen={props.isOpen} onClick={props.closeModal}/>
            <ModalContainer isOpen={props.isOpen}>
                {props.children}
                <CloseButton onClick={props.closeModal}>
                    <FontAwesomeIcon icon={faXmark}/>
                </CloseButton>
            </ModalContainer>
        </>
    );
};
