import {createGlobalStyle} from "styled-components";
import {Colors} from "./ColorUtils";
import {FontSizes} from "./FontUtils";
// @ts-ignore
import largeBackground from "../../images/common/paprotki-duze.svg"
// @ts-ignore
import mediumBackground from "../../images/common/paprotki-srednie.svg"
// @ts-ignore
import mobileBackground from "../../images/common/paprotki-male.svg"
import {MD_MAX_SIZE, XS_MAX_SIZE} from "../util/ViewSizeUtils";

interface GlobalStyleProps {
    withBackground: boolean;
}

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  @font-face {
    font-family: "bely";
    font-display: swap;
    src: url(/fonts/bely.woff2) format("woff2");
  }

  @font-face {
    font-family: "bely-bold";
    font-display: swap;
    src: url(/fonts/bely-bold.woff2) format("woff2");
  }

  @font-face {
    font-family: "bely-italic";
    font-display: swap;
    src: url(/fonts/bely-italic.woff2) format("woff2");
  }

  .stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

  body {
    background-color: ${Colors.BLACK};
    font-family: bely;
    font-size: ${FontSizes.SMALL};
    margin: 0;
    -webkit-font-smoothing: antialiased;

    ${props => props.withBackground ? "background-image: url(" + largeBackground + ")" : ""};

    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      ${props => props.withBackground ? "background-image: url(" + mediumBackground + ")" : ""};
    }

    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      ${props => props.withBackground ? "background-image: url(" + mobileBackground + ")" : ""};
    }

    ${props => props.withBackground
            ? "background-position: top right;\n" +
            "background-repeat: repeat;\n" +
            "background-size: auto;\n" +
            "background-color: ${props => props.color};\n"
            : ""};
  }
  
  :any-link {
    text-decoration: none;
  }

  .row {
    margin: 0;
  }
`;
