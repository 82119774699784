import React, {FC, PropsWithChildren} from "react";
import Carousel, {RenderArrowProps} from "react-elastic-carousel";
import {Arrow} from "../carousel/arrow/Arrow.component.tsx";

interface GalleryCarouselProps {
    activeIndex?: number;
}

export const GalleryCarousel: FC<PropsWithChildren<GalleryCarouselProps>> = (props: PropsWithChildren<GalleryCarouselProps>) => {

    // @ts-ignore
    return <Carousel
        itemsToShow={1}
        itemsToScroll={1}
        initialActiveIndex={props.activeIndex}
        isRTL={false}
        renderPagination={() => <></>}
        renderArrow={(props: RenderArrowProps) => <Arrow  {...props}/>}
    >
        {props.children}
    </Carousel>;

};
