import styled from "styled-components";
import {Colors} from "../../../style/ColorUtils";
import {SM_MAX_SIZE, XS_MAX_SIZE} from "../../../util/ViewSizeUtils";

interface StyledArrowProps {
    isLeft: boolean;
}

export const StyledArrow = styled.div<StyledArrowProps>`
  color: ${Colors.BLACK};
  display: flex;
  align-items: center;
  font-size: 64px;

  @media only screen and (max-width: ${SM_MAX_SIZE}px) {
    font-size: 48px;
  }
  
  ${props => props.isLeft ? "padding-right: 12px;" : "padding-left: 12px;"};
  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    padding: 0;
    font-size: 32px;
  }

  svg {
    cursor: pointer;
  }
`;
