
export const isDefined = (object: any): boolean => {
    return object !== null && object !== undefined;
};

export const allDefined = (objects: any[]): boolean => {
    return objects.every(o => isDefined(o));
};

export const isNotDefined = (object: any): boolean => {
    return !isDefined(object);
};

export const isObjectIncludingAllElementsFromArray = (object: any, array: any[]): boolean => {
    return array.every((otherObject: any) => object.includes(otherObject));
};

export const groupByKey = (list: any[], key: string): Map<any, any[]> => new Map(Object.entries(
    list.reduce((hash: any, obj: any) => ({...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj)}), {})));

export const getObjectFromMap = (map: Map<any, any>) => {
    const obj: any = {};
    for (const key of map.keys()) {
        obj[key] = map.get(key);
    }
    return obj;
};
