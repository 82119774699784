import styled from "styled-components";
import {Colors} from "../../style/ColorUtils";

interface SpacerProps {
    backgroundColor?: string;
    height?: string;
}

export const Spacer = styled.div<SpacerProps>` {
  background-color: ${Colors.YELLOW};
  width: 100%;
  height: ${(props: SpacerProps) => props.height};
}`

Spacer.defaultProps = {
    backgroundColor: Colors.YELLOW,
    height: "34px"
}
