import styled from "styled-components";
import {Colors} from "../../style/ColorUtils";
import {XS_MAX_SIZE} from "../../util/ViewSizeUtils";

export const StyledSocialMedia = styled.div`
  position: fixed;
  bottom: 44px;
  right: 25px;
  z-index: 30;
  
  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    right: 12px;
  }

  font-size: 45px;
  display: inline-flex;

  .f-social-media-icon {
    text-decoration: none;
    color: ${Colors.WHITE};
    filter: drop-shadow(0 0 5px rgb(0 0 0 / 2.4));
    padding: 5px;
    transition: all 0.2s ease-in-out;
  }

  .f-social-media-icon:hover {
    font-size: 55px;
    padding: 0;
  }

  .i-social-media-icon {
    text-decoration: none;
    color: ${Colors.WHITE};
    filter: drop-shadow(0 0 5px rgb(0 0 0 / 2.4));
    padding: 5px;
    transition: all 0.2s ease-in-out;
  }

  .i-social-media-icon:hover {
    font-size: 55px;
    padding: 0 0 0 1px;
  }
`;
