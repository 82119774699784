import * as React from 'react'
import {FC} from 'react'
import {StyledLogo} from "./Logo.style";
import {Images} from "../../../../images/Images";
import {LocalizedLink} from "../../localizedLink/LocalizedLink.component";
import {getHomePageLink} from "../service/Links.service";
import {ImageCustom} from "../../image/ImageCustom.component";

export const Logo: FC = () => {
    return <StyledLogo>
        <LocalizedLink to={getHomePageLink()}>
            <ImageCustom image={Images.LOGO_RECTANGLE}/>
        </LocalizedLink>
    </StyledLogo>
}
