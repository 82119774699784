import styled from "styled-components";

export const StyledFooterLogo = styled.div`
  div {
    margin-left: auto;
    margin-right: auto;
  }

  .logo-square {
    max-width: 224px;
    text-align: center;
  }
`;
