import styled from "styled-components";

export interface StyledImageCustomProps {
    isClickable: boolean;
    withPulse?: boolean;
}

export const StyledImageCustom = styled.img<StyledImageCustomProps>`
  transition: all 0.3s ease-in-out;
  transform: scale(1, 1);

  :hover {
    ${props => props.isClickable ? "cursor: pointer;" : ""}
    ${props => props.isClickable ? "filter: brightness(80%);" : ""}
    ${props => props.isClickable ? "transform: scale(1.04, 1.04)" : ""}
  }
`;
