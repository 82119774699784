import React, {FC, PropsWithChildren} from "react";
import {translate} from "../../../lang/TranslationUtils";
import {OutboundLink} from "gatsby-plugin-google-analytics";

interface ExternalLinkProps {
    to: string;
    ariaLabel?: string;
    className?: string;
}

export const ExternalLink: FC<PropsWithChildren<ExternalLinkProps>> = (props: PropsWithChildren<ExternalLinkProps>) => {
    return <OutboundLink className={props.className} href={props.to} target={"_blank"}
                         aria-label={translate(props.ariaLabel)}>{props.children}</OutboundLink>;
};
